/**
*
* Content (text stylisation connected to CMS WYSIWYG editor)
*
**/

%smallText {
    @include dynamicFontSize(12, 14, 480, 1600);
}

%largeText {
    @include dynamicFontSize(16, 22, 480, 1600);
    line-height: 1.5;
}

.content {
    p,
    li,
    td {
        @include dynamicFontSize(14, 16.5, 480, 1600);
        line-height: 1.55;
        font-weight: 300;

    }

    .content__small-text {
        @extend %smallText;

        li,
        td,
        p {
            @extend %smallText;
        }
    }

    .content__large-text {
        @extend %largeText;

        li,
        td,
        p {
            @extend %largeText;
        }
    }

    p:not(:last-child) {
        margin: 0;
        padding-bottom: .9em;
    }

    ul:not(:last-child),
    ol:not(:last-child) {
        padding-bottom: 1em;
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p + ul,
    p + ol {
        //margin-top: -.7em !important;
    }

    blockquote {
        @extend .blockquote;
    }

    &__text-with-icon {
        position: relative;
        padding: 6px 0 6px 30px;
        margin: 0;

        .content & {
            line-height: 1.2;
        }

        &-image {
            position: absolute;
            top: 7px;
            left: 0;

            width: 20px;
            height: 20px;
        }
    }
}