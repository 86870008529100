
.filter {
    padding-left: 5px;
    margin-bottom: 0.5em;
    border-bottom: 1px solid $colorGrey;

    &--left-separated {
        margin-left: 5px;
    }

    &__label {
        position: relative;
        top: -2px;
        font: {
            size: rem(14px);
        }
        color: $colorMain;
    }

    &__select {
        display: block;
        width: 100%;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 1px 55px 3px 0;
        font: {
            size: rem(18px);
        }
        border: none;
        background: url(../images/down-arrow.svg) no-repeat;
        background-position: calc(100% - 5px) center;
        background-size: 10px auto;

        &:focus {
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }
    }
}
