/*==========  COLORS  ==========*/
// Color helper classes are next to color definitions in _utils.scss
@each $colorName, $color in $colors {
    .color-#{$colorName} {
        color: $color;
        fill: $color;

        a {
            color: $color;
        }
    }
}

/*==========  GENERIC  ==========*/
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-upper {text-transform: uppercase;}

.right {float: right;}
.left {float: left;}
.nowrap {white-space: nowrap;}
.single-word {word-spacing: 9999px; }
.underline {text-decoration: underline; }

.block {display: block;}

$gapSizes: (0: 0, 5: 0.5, 10: 1, 15: 1.5, 20: 2, 25: 2.5, 30: 3, 35: 3.5, 40: 4);

@each $var, $size in $gapSizes {
    $value: #{$size}em;

    /*==========  MARGINS  ==========*/
    .mt--#{$var}em {margin-top: $value}
    .mb--#{$var}em {margin-bottom: $value}
    .ml--#{$var}em {margin-left: $value}
    .mr--#{$var}em {margin-right: $value}

    /*==========  PADDING  ==========*/
    .pt--#{$var}em {padding-top: $value}
    .pb--#{$var}em {padding-bottom: $value}
    .pl--#{$var}em {padding-left: $value}
    .pr--#{$var}em {padding-right: $value}

    .pall--#{$var}em {padding: $value}
}

/*========= RWD HELPERS =========*/
@media screen and (min-width: 200px) and (max-width: 568px) {
    .hide-on-mobile {
        display: none;
    }
}

@media screen and (min-width: 569px) and (max-width: 767px) {
    .hide-on-sm,
    .hide-on-sm-down {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .hide-on-md,
    .hide-on-md-down {
        display: none;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .hide-on-lg,
    .hide-on-lg-down {
        display: none;
    }
}

/*========= HIDING =========*/

/*
 * Hide visually and from screen readers:
 */
.hidden {display: none !important;}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {visibility: hidden;}


/*
 * Stop scrolling (useful when the mobile menu is opened and you want to prevent scrolling on the scene behind it - in <body>)
*/
.stop-scrolling {
    height: 100%;
    overflow: hidden;
}