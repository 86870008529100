/**
*
* Breadcrumbs
*
**/

.breadcrumbs {
    clear: both;
    padding: 0.5em 0;
    color: #777;

    @media all and (max-width: 768px) {
        font-size: rem(15px);
    }

    li {
        @extend %verticalMiddleInlineBlock;

        &:not(:last-child):after {
            content: '';
            @extend %verticalMiddleInlineBlock;
            
            width: 15px;
            height: 15px;
            margin: 0 2px 0 5px;
            background: url(../images/icons/tick-grey.svg) top center no-repeat;
        }
    }

    a {
        color: $colorDarkGreen;
        text-decoration: underline;

        &:hover {
            color: $colorLightGreen;
        }
    }
}