/**
*
* Pagination
*
**/

.pagination {
    clear: both;
    padding: 3em 0;
    text-align: center;

    li,
    a {
        display: inline-block;
    }

    a {
        padding: .4em 1em;
        border-radius: 5px;

        text-transform: uppercase;
        letter-spacing: -0.02em;

        @include dynamicFontSize(16, 21, 480, 1600);
        font-weight: 600;

        transition: background-color 0.2s, color 0.3s;

        &.active {
            background-color: $colorDarkGreen;
            color: #fff;

            &:hover {
                color: #333;
            }
        }

        &:hover {
            background-color: #e8e8e8;
        }
    }

    &__previous,
    &__next {
        color: $colorDarkGreen;
    }
}