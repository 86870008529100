.items-group {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap row;

    width: 100%;
    margin-bottom: 1em;

    &:not(.items-group--no-padding) {
        padding: {
            left: 1em;
            right: 1em;
        }
    }

    &:not(:last-child) {
        margin-bottom: 1em;
    }

    &__item {
        &:not(.items-group__item--post) {
            padding: 20px;
        }
    }

    &--2 {
        .items-group__item {
            @media all and (min-width: 700px) {
                width: 48%;
            }
        }
    }

    &--3 {
        .items-group__item {
            @media all and (min-width: 700px) {
                width: 32%;
            }
        }
    }

    &--4 {
        .items-group__item {
            @media all and (min-width: 568px) and (max-width: 1023px) {
                width: 48%;
            }

            @media all and (min-width: 1024px) {
                width: 24%;
            }
        }
    }

    &--5 {
        @media all and (min-width: 568px) and (max-width: 900px) {
            justify-content: center;
        }

        @media all and (min-width: 901px) and (max-width: 1180px) {
            justify-content: initial;
        }

        .items-group__item {
            @media all and (min-width: 568px) {
                margin: {
                    left: 1%;
                    right: 1%;
                }
            }

            @media all and (min-width: 568px) and (max-width: 900px) {
                width: 48%;

            }

            @media all and (min-width: 901px) and (max-width: 1180px) {
                width: 31.3%;
            }

            @media all and (min-width: 1181px) {
                width: 18%;
            }
        }
    }

    &--small-items-inside {
        @media all and (max-width: 767px) {
            .items-group__item {
                width: 50%;
            }
        }
    }
}