

$mainContainerMaxWidth: 1200;
$narrowContainerMaxWidth: 1060;
$narrowContainerMaxWidth2: 940;
$superNarrowContainerMaxWidth: 750;
$largeContainerMaxWidth: 1500;

.container {
  margin-right: auto;
  margin-left: auto;

  width: 92%;
  max-width: ($mainContainerMaxWidth)+px;

  &-narrow {
    @extend .container;
    max-width: ($narrowContainerMaxWidth)+px;
  }

  &-narrow2 {
    @extend .container;
    max-width: ($narrowContainerMaxWidth2)+px;
  }

  &-super-narrow {
    @extend .container;
    max-width: ($superNarrowContainerMaxWidth)+px;
  }

  &-large {
    @extend .container;
    max-width: ($largeContainerMaxWidth)+px;
  }

  &-fluid {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }
}
