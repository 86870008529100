/**
*
* Forms
*
**/

.form {
    position: relative;

    fieldset {
        @include clearfix();
    }

    &-block {
        padding: 2rem 3rem;
    }

    &--mt {
        margin-top: 3rem;
    }

    &--white {
        .form__label {
            color: #fff;
        }

        .form__input,
        .form__textarea {
            border-bottom-color: #fff;

            &:focus {
                outline:none;
                border-bottom: 1px solid $colorWhite;
                caret-color: $colorWhite;

                /* active state */
                ~ .form__bar:before,
                ~ .form__bar:after {
                    background-color: $colorWhite;
                }
            }
        }
    }
}

.ie9 .form__input {
    line-height:normal;
} /* Get the stuff to line up right */

.form__textarea {
    width: 100%;
    min-height: 8em;
}


// Checkbox & Radio inputs
.form__radio,
.form__checkbox {
    position: absolute;
    left: -999em;
}

.form__radio-label,
.form__checkbox-label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.form__radio-label:before,
.form__checkbox-label:before {
    display: inline-block;
    position: relative;
    top: 2px;
    left: -2px;
    content:'';
    width: 25px;
    height: 23px;
    background: url(../images/formelements.png) top left no-repeat;
}

.form__checkbox + .form__checkbox-label:before {
    background-position: 0 -25px;
}

.form__checkbox:checked + .form__checkbox-label:before {
    background-position: 0 0 ;
}

.form__radio + .form__radio-label:before {
    background-position: -25px -25px;
}

.form__radio:checked + .form__radio-label:before {
    background-position: -25px 0;
}

.form__row {
    display: table;
    width: 100%;
    clear:both;
    padding: 6px 0;

    &__left,
    &__right {
        display: table-cell;
        vertical-align: top;
    }

    &__left {
        width: 49%;
        padding-right: 1%;
    }

    &__right {
        width: 49%;
        padding-left: 1%;
    }

    &--input-and-submit {
        display: flex;

        .form__row__left,
        .form__row__right {
            display: block;
            width: auto;
        }

        .form__row__left {
            flex: 1;
        }
    }
}


.form {
    &__group {
        position:relative;
        margin-bottom: 0.6rem;
    }

    &__input,
    &__textarea {
        font-size: rem(16px);
        padding: 10px 10px 10px 5px;
        display:block;
        width: 100%;
        border: none;
        border-bottom: 1px solid $colorMain;
        background-color: transparent;

        &:focus {
            outline:none;
            border-bottom: 1px solid $colorMain;
            caret-color: $colorMain;

            /* active state */
            ~ .form__bar:before,
            ~ .form__bar:after {
                width: 50%;
                background-color: $colorMain;
            }

            ~ .form__highlight {
                animation: inputHighlighter 0.3s ease;
            }
        }

        &:focus,
        &.filled {

            /* active state */
            ~ .form__label {
                top:-12px;
                font-size:13px;
                //color: rgba($colorWhite, 0.7);
            }
        }
    }

    &__label {
        color: $colorMain;
        font-size: 17px;
        font-weight: 300;
        position:absolute;
        pointer-events:none;
        left:5px;
        top:10px;
        transition:0.2s ease all;
    }

    &__bar {
        position:relative;
        display:block;

        &:before,
        &:after {
            content:' ';
            height:2px;
            width:0;
            bottom:1px;
            position:absolute;
            background:#5264AE;
            transition:0.2s ease all;
        }

        &:before {
            left:50%;
        }

        &:after {
            right:50%;
        }
    }

    &__highlight {
        position:absolute;
        height:60%;
        width:100px;
        top:25%;
        left:0;
        pointer-events:none;
    }
}


.form__response {
    margin-bottom: 1.5em;
    
    &--ok {
        background: green;
    }

    &--error {
        background: red;
    }

    p {
        padding: 10px;

        font-size: 1em !important;
        color: #fff;
        text-shadow: none;
    }
}


/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
    from { background: $colorWhite; }
  to    { width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
    from { background: $colorWhite; }
  to    { width:0; background:transparent; }
}
@keyframes inputHighlighter {
    from { background: $colorWhite; }
  to    { width:0; background:transparent; }
}

