$overlayColors: (
    'blue': $colorBlue, 
    'light-green': $colorLightGreen, 
    'dark-green': $colorDarkGreen
);

.overlay {
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100%;
    }

    @each $colorName, $color in $overlayColors {
        &--#{$colorName} {
            &:after {
                background-color: rgba($color, 0.7);
            }
        }
    }
}