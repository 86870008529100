.post-details {
    @extend %largeText;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &--small {
    	font-size: rem(16px);
    }
}