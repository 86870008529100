/**
*
* Main footer
*
**/

.l-footer {
    padding: 20px 0;
    background-color: #202020;

    font: {
        size: rem(13px);
        weight: 400;
    }
    letter-spacing: -0.03em;
    color: #797979;

    svg {
        fill: #797979;
    }

    a {
        color: #797979;
    }

    &__wrapper {
        @media all and (min-width: 768px) {
            display: flex;
            align-items: center;
        }
    }

    &__left {
        display: flex;
        align-items: center;

        p {
            margin: 0 0 0.3em 0;
        }
    }

    &__right {
        @media all and (max-width: 767px) {
            margin-top: 2em;
            text-align: center;
        }
    }

    &__logo {
        height: 65px;
        margin-right: 2em;
        opacity: 0.4;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 1;
        }
    }

    &__bp {
        @extend %verticalMiddleInlineBlock;
        padding: 5px 34px 5px 0;

        background: url(../images/icons/bp-logo.svg) top right no-repeat;
        background-size: 23px auto;

        &:hover {
            text-decoration: underline;
        }
    }
}