.btn-icon {
    text-align: center;
    border-radius: 10px;
    transition: background .5s;

    &__icon {
        margin-bottom: 1em;
    }

    &__title {
        display: block
    }

    &--white {
        color: #fff;
        text-transform: uppercase;
        line-height: 1.1;

        svg {
            fill: #fff;
        }

        &:hover {
            background-color: rgba(#fff, 0.1);
        }
    }
}