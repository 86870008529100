/**
*
* Sass Variables
*
**/

/* Colors */

$colorMain: #666;
$colorBlue: #04a8ed;
$colorDarkGreen: #166936;
$colorLightGreen: #7acd03;
$colorWhite: #fff;
$colorGrey: #9f9f9f;
$colorLightGrey: #f5f5f5;

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);
$colors: (
    'blue': $colorBlue, 
    'dark-green': $colorDarkGreen, 
    'light-green': $colorLightGreen, 
    'white': $colorWhite
);

$channels: (twitter: #4099ff, linkedin: #007bb6, facebook: #3b5998, gplus: #dd4b39, email: #3bb55e);