.specification {
    margin: 1em 0;

    &__row {
        display: table;
        justify-content: stretch;

        margin: 0.7em 0;
    }

    &__label {
        display: table-cell;
        width: 80px;
        font-weight: 500;
    }
}