.section {
    position: relative;
    padding: {
        top: 1em;
        bottom: 1em;
    }
    overflow: hidden;

    @media all and (min-width: 1024px) {
        padding: 2em 0;
    }

    &__container {
        position: relative;
        z-index: 2;
    }

    &__headline {
        text-align: center;
        margin-bottom: 2em;
    }

    &__icon {
        height: 150px;
        
        @media all and (min-width: 1024px) {
            margin: 2em 0 3em 0;
        }
    }

    &__footer-cta {
        margin-top: 2em;

        &-headline {
            margin-bottom: 0.8em;
        }
    }

    &--statement {
        padding: 1.5em 0;

        @media all and (min-width: 768px) and (max-width: 1023px) {
            padding: 2.5em 0;
        }

        @media all and (min-width: 1024px) {
            padding: 3.5em 0;
        }
    }

    &--contact-form {
        position: relative;
        padding: 3em 0;

        &__container {
            @extend .flex;

            width: 90%;
            margin: {
                top: 2em;
                left: auto;
                right: auto;
            }

            &:after,
            &:before {
                display: none;
            }

            @media all and (max-width: 767px) {
                display: block;
            }

            @media all and (min-width: 768px) {
                > [class*=col-] {
                    padding-bottom: 0;
                }
            }
        }

        &__separated {
            @media all and (min-width: 768px) {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;

                    width: 1px;
                    height: 100%;

                    background-color: rgba(#fff, 0.5);
                }
            }
        }
    }

    &--negative-bottom-margin {
        overflow: visible;
        
        @media all and (min-width: 1024px) {
            .container {
                z-index: 10;
                margin-bottom: -7em;
            }
        }
    }
}