.search {
    max-width: 700px;
    background-color: rgba(#fff, 0.1);
    border-radius: 10px;
    text-align: left;

    &--white {
        a {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }

        .search__input {
            color: #fff;
            border-bottom-color: #fff;
        }

        .search__submit {
            background: none;

            svg {
                fill: #fff;
            }
        }

        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: rgba(#fff, 0.5);
        }

        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
           color: rgba(#fff, 0.5);
           opacity:1;
        }

        ::-moz-placeholder { /* Mozilla Firefox 19+ */
           color: rgba(#fff, 0.5);
           opacity: 1;
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
           color: rgba(#fff, 0.5);
        }

        ::-ms-input-fff { /* Microsoft Edge */
           color: rgba(#fff, 0.5);
        }
    }

    &--simple {
        .search__input {
            padding-bottom: 10px;
            font-weight: 400;
            border-bottom-color: $colorGrey;
        }

        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: rgba(#999, 0.7);
        }

        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
           color: rgba(#999, 0.7);
           opacity:1;
        }

        ::-moz-placeholder { /* Mozilla Firefox 19+ */
           color: rgba(#999, 0.7);
           opacity: 1;
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
           color: rgba(#999, 0.7);
        }

        ::-ms-input-fff { /* Microsoft Edge */
           color: rgba(#999, 0.7);
        }
    }

    &--centred {
        padding: 20px 30px;
        margin: {
            left: auto;
            right: auto;
        }

        .search__input {
            border-bottom-color: #fff;
        }
    }

    &__inputs-group {
        position: relative;
    }

    &__input {
        width: 100%;
        padding-bottom: 5px;

        font: {
            size: 32px;
            weight: 500;
        }

        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        background: none;
        
        outline: none;
    }

    &__row {
        margin-top: 1em;
    }

    &__submit {
        padding: 0.15em 0.7em;
        border-radius: 5px;
        background-color: $colorBlue;

        svg {
            fill: #fff;
        }

        &--floating-right {
            position: absolute;
            top: 2px;
            right: 0;
        }
    }
}