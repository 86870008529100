/**
*
* Media - imgs/videos
*
**/

img,
video,
object {
    max-width: 100%;
}
