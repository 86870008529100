/**
*
* Top navigation
*
**/

.l-navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-left: 50px;

    @media all and (max-width: 1180px) {
        padding: 0 20px;
    }
        
    a:hover {
        text-decoration: underline;
    }

    &__logo {
        max-width: none;

        .l-navbar--compact & {
            margin: 10px 0;

            @media all and (max-width: 1023px) {
                height: 40px;
            }
        }
    }

    &__links {
        @media all and (max-width: 1023px) {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll;

            height: calc(100vh - 60px);
            padding: 10px;

            background-color: #fff;


            &--visible {
                display: block;
            }
        }

        @media all and (min-width: 1024px) {
            text-align: right;
        }
    }

    &:not(.l-navbar--compact) {
        padding-right: 50px;
        color: #fff;

        a {
            color: #fff;
        }

        svg {
            fill: #fff;
        }

        @media all and (max-width: 1023px) {
            display: none;
        }
    }

    &--compact {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        transition: top 0.7s;

        background-color: #fff;
        color: $colorDarkGreen;

        a {
            color: $colorDarkGreen;
        }

        svg {
            fill: $colorDarkGreen;
        }
    }

    &--hidden {
        @media all and (min-width: 1024px) {
            top: -90px;
        }
    }

    &__main-nav {
        &-item {
            @extend %verticalMiddleInlineBlock;
            
            @media all and (max-width: 1023px), (min-width: 1181px) {
                margin: 0 7px;
            }
        }

        &-link,
        &-text {
            @extend %verticalMiddleInlineBlock;
            padding: 10px;

            font: {
                size: rem(16px);
                weight: 400;
            }

            @media all and (min-width: 1024px) and (max-width: 1180px) {
                font-size: rem(14px);
            }
        }

        &-item,
        &-link,
        &-text {
            @media all and (max-width: 1023px) {
                display: block;
                width: 100%;
            }
        }

        &-link-icon {
            margin-right: 0.4em;
        }
    }

    &__secondary-nav {
        margin: 0.25em 0 0.2em 0;

        &-item {
            @extend %verticalMiddleInlineBlock;
            margin: 0 13px;

            @media all and (max-width: 1023px) {
                display: block;
                width: 100%;
            }
        }

        &-link,
        &-text {
            @extend %verticalMiddleInlineBlock;
            padding: 5px;

            font: {
                size: rem(12px);
                weight: 400;
            }

            @media all and (max-width: 1023px) {
                padding: 7px;
                font-size: rem(14px);
            }
        }
    }

    &__button-control {
        @extend %verticalMiddleInlineBlock;
        padding: 10px;

        @media all and (min-width: 1024px) {
            display: none;
        }
    }
}