/**
*
* Blockquote
*
**/

.blockquote {
    display: inline-block;
    margin: 1em 0;

    > p {

        &:first-child:before,
        &:first-child:after {
            position: absolute;
            font-size: 1.4em;
            color: #fff;
        }

       &:first-child:before {
            content: '\201C';
            width: 25px;
            height: 25px;

            margin-left: -20px;

            font-size: 0.9em;
            font-weight: 300;
        }

        &:last-of-type:after {
            content: '\201D';
            width: 25px;
            height: 25px;

            font-size: 0.9em;
            font-weight: 300;
        }
    }

    &__footer {
        text-align: right;
        @include dynamicFontSize(20, 40, 480, 1600);
    }
}