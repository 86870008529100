.bg-media {
    &--video {
        @extend %fullscreenLayer;

        video {
            object-fit: cover;
            min-height: 100%;
            min-width: 100%;
            width: 100%;
        }
    }

    &--image {
        @extend %fullscreenLayer;
        background-size: cover;
        background-position: center center;
    }

    .card & {
        border-radius: 3px;
    }
}