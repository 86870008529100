/**
*
* List
*
**/

// General
ul,
ol {
    padding: 0;
    margin: 0;
}

ul,
.list-ol {
    list-style-type: none;
}

.list-ul,
.list-ol {
    padding-top: 0.4em;
    padding-bottom: 0.4em;

    li {
        padding: 1px 0 1px 0;
        position: relative;
        margin-bottom: 2px;

        &:before {
            position: absolute;
            overflow: hidden;
            display: block;
            content: " ";
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

// Unordered list
.list-ul,
.content .list-ul {
    padding-left: 30px;

    li {
        &:before {
            top: .2em;
            left: -30px;

            width: 22px;
            height: 22px;

            background: url(../images/icons/tick-grey.svg);
            background-repeat: no-repeat;
            background-position: 0 1px;
            background-size: 10px auto;
        }
    }

    $tickColours: ('light-green', 'dark-green', 'blue');

    @each $color in $tickColours {
        &--#{$color} {
            li {
                &:before {
                    background-image: url(../images/icons/tick-#{$color}.svg);
                }
            }
        }
    }

    .content__small-text & {
        padding-left: 20px;

        li {
            &:before {
                left: -20px;
                
                width: 17px;
                height: 17px;
                background-size: 8px auto;
                background-position: 0 1px;
            }
        }
    }

    .content__large-text & {
        li {
            &:before {
                background-position: 0 4px;
            }
        }
    }
}

// Ordered list
.list-ol,
.content .list-ol {
    counter-reset: item;
    padding-left: 30px;

    li {
        &:before {
            left: -30px;

            width: 25px;
            content: counters(item, ".") ". ";
            counter-increment: item;

            text-align: center;
            line-height: 1.5;
        }
    }
}