/**
*
* Button
*
**/

%borderedButton {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

.btn {
    position: relative;

    @include inline-block();

    font-weight: 400;
    text-align: center;

    box-shadow: 0 0 1px transparent;
    border-radius: 5px;
    border: 1px solid #fff;
    transition: all 0.2s ease;

    &--full-width {
        width: 100%;
    }

    // Size
    &--big {
        padding: rem(5px) rem(30px);

        font-size: rem(23px);
        line-height: 1.1;
    }

    &--medium {
        padding: rem(9px) rem(35px);

        font-size: rem(19px);
        line-height: 1.1;
    }

    &--small {
        padding: rem(8px) rem(25px);

        font-size: rem(16px);
        line-height: 1.1;
    }

    // Type
    @each $colorName, $color in $colors {
        &--bordered-#{$colorName} {
            @extend %borderedButton;
            border-color: $color;
            color: $color;

            &:hover,
            &:active,
            &:focus {
                background-color: $color;
                color: #fff;
            }
        }
    }

    @each $colorName, $color in $colors {
        &--bg-#{$colorName} {
            @extend %borderedButton;
            border-color: $color;

            background-color: $color;
            color: #fff;

            &:hover,
            &:active,
            &:focus {
                background-color: #fff;
                color: $color;
            }
        }
    }

        // exceptions
        &--bordered-white {
            &:hover,
            &:active,
            &:focus {
                color: #222;
            }
        }

        &--bg-white {
            color: #222;

            &:hover,
            &:active,
            &:focus {
                background-color: #222;
                border-color: #222;
            }
        }

    @each $colorName, $color in $colors {
        &--text-#{$colorName} {
            color: $color;

            &:hover,
            &:active,
            &:focus {
                background-color: $color;
                color: #fff;
                border-color: $color;
            }
        }
    }
}