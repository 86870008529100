
.share {
    margin: 1.5rem 0;
    font-weight: 400;

    &__headline {
        margin-bottom: 1rem;
    }

    &__icons {
        margin: .3em 0;

        li {
            margin: 1em 0;

            a {
                @extend %verticalMiddleInlineBlock;
                color: $colorDarkGreen;

                &:hover {
                    color: $colorBlue;
                }
            }
        }

        svg {
            fill: #fff;
        }
    }

    &__icon {
        @extend %verticalMiddleInlineBlock;
        width: 30px;
        height: 30px;
        margin: {
            top: -2px;
            right: 0.7em;
        }

        border-radius: 3px;
        background-color: #202020;
        transition: background-color 0.2s ease;
    }

    %icon {
        svg {
            width: 30px;
            height: 30px;
            
            transition: opacity .2s ease;
        }
    }

    @each $ico, $color in $channels {
        &__#{$ico} {
            @extend %icon;

            &:hover .share__icon {
                background-color: $color;
            }
        }
    }
}