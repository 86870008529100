/**
*
* Table
*
**/

.table {
    text-align: left;

    td,
    th {
        padding: 10px;
        border: 1px solid #ccc;
        border-collapse: collapse;
    }

    thead {
        background: #ccc;
        color: #666;
        font-weight: 600;
    }
}
