/**
*
* Headlines
*
**/
%headlineCommonStyles {
    display: block;
    margin: 0 0 0.25em 0;
}

.headline {

    &-1 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(30, 68, 480, 1600);
        font-weight: 300;
        line-height: 1;
        letter-spacing: -0.01em;
    }

    &-2 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(20, 45, 480, 1600);
        font-weight: 300;
        letter-spacing: 0.015em;
        line-height: 1.2;
    }

    &-3 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(20, 36, 480, 1600);
        font-weight: 300;
        letter-spacing: -0.01em;
        line-height: 1.3;
    }

    &-4 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(30, 65, 480, 1600);
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: -0.008em;
    }

    &-4-bold {
        @extend %headlineCommonStyles;
        @extend .headline-4;
        font-weight: 500;
    }

    &-5 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(19, 22, 480, 1600);
        letter-spacing: -0.02em;
        font-weight: 300;
        line-height: 1.2;
    }

    &-6 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(20, 45, 480, 1600);
        font-weight: 300;
        letter-spacing: -0.01em;
    }

    &-7 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(22, 28, 480, 1600);
        letter-spacing: -0.02em;
        font-weight: 300;
        line-height: 1.2;
    }

    &-7-bold {
        @extend %headlineCommonStyles;
        @extend .headline-7;
        font-weight: 500;
    }

    &-8 {
        @extend %headlineCommonStyles;
        @include dynamicFontSize(14, 18, 480, 1600);
        font-weight: 300;
        line-height: 1.2;
    }
}