/**
*
* Top header
*
**/
.l-header {
    position: relative;
    overflow: hidden;

    &--full-height {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 100px 0 20px 0;

        height: 100vh;
        min-height: 700px;

        @media all and (min-width: 1023px) {
            min-height: 760px;
            padding: 27px 0 10px 0;
        }
    }

    &--short {
        padding: 2.5em 0;

        @media all and (min-width: 1024px) {
            padding: 6em 0;
        }
    }

    &--simple {
        padding: 2em 0 0 0;

        &__column {
            @media all and (max-width: 768px) {
                margin-bottom: 1em;
            }
        }

        &__container {
            @media all and (min-width: 769px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &:not(.l-header--full-height) {
        @extend %navbarTopMargin;
    }

    &__bg-media {
        z-index: -1;
    }

    &__icon {
        width: 90px;
        height: 90px;

        @media all and (min-width: 1024px) {
            margin-top: 4em;
        }
    }

    &__container {
        position: relative;
        z-index: 1;

        height: inherit;
    }

    &__content {
        &-vertically-aligned {
            width: 100%;
            @extend %verticalAlignment;
        }
    }
}