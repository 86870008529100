/**
*
* Top navigation
*
**/

.l-main {
	//padding: 3em 0;
	background-color: $colorLightGrey;

	&--margin-due-to-navbar {
		@extend %navbarTopMargin;
	}
}