.burger {
    background-color: transparent;

    @media all and (min-width: 1024px) {
        display: none;
    }

    div,
    span {
        display: block;
        width: 32px;
        height: 2px;
        background-color: $colorDarkGreen;
        margin-bottom: 7px;
        transition: all 0.5s ease;

        &:last-child {
            margin-bottom: 2px;
        }
    }

    &--open {
        .line2 {
            opacity: 0;
        }

        .line1 {
            transform: rotate(45deg) translateX(6px) translateY(6px);
        }

        .line3 {
            transform: rotate(-45deg) translateX(7px)  translateY(-7px);
        }
    }
}