/*==========  GLOBAL STYLES  ==========*/

html {
    box-sizing: border-box;
}

*, 
*:before, 
*:after {
    box-sizing: inherit;
}

html,
body,
button {
    //-moz-osx-font-smoothing: antialiased;
    //-webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

html {
    background-color: #202020;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: $colorMain;
}

ul, li {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;

    &:not([class]) {
        color: $colorDarkGreen;

        &:hover {
            color: #222;
            text-decoration: underline;
        }
    }
}

b,
strong {
    font-weight: 500;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}
