.card {
    width: 100%;
    position: relative;
    z-index: 10;
    overflow: hidden;
    color: inherit;

    margin-bottom: 1em;
    
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    transition: all 0.5s ease;

    &__icon {
        margin-bottom: 0.5em;
        height: 48px;

        &--large {
            height: 65px;
            margin: 1.5em 0;
        }
    }

    &__title {
        display: block;
    }

    &__image {
        margin: 0;
        height: 150px;
        text-align: center;

        background-size: cover;
        background-position: center center;

        @media all and (min-width: 768px) {
            height: 200px;
        }

        img {
            visibility: hidden;
            height: 100%;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
        padding: 1.5em 1em;

        &--more-space {
            padding: 1.5em;

            @media all and (min-width: 1024px) {
                padding: 2.5em 3em 1em 3em;
            }
        }

        &--sticky-footer {
            position: static;
            padding-bottom: 50px;
        }
    }

    &__sticky-footer {
        position: absolute;
        bottom: 0;
        right: 1em;
    }

    &--post {
        padding-bottom: 50px;
    }
}

a.card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}